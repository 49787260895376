<template>
  <div>
    <v-app-bar class="mt-2" color="rgba(0, 0, 0, 0)" flat dense>
      <v-toolbar-title>
        <span class="text-h5"> 営業ポイント成績表</span>
        <span class="text-h6 ml-8">{{ dateStart | libertyTerm }} 期</span>
        <span class="text-subtitle-1 ml-2">{{ dateStart | date }} - {{ dateEnd | date }}</span>
      </v-toolbar-title>

      <v-spacer />
      <v-sheet width="200">
        <v-select v-model="year" label="期間" :items="years" @change="onDateChanged" :full-width="false" />
      </v-sheet>

      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list width="300" outlined>
          <v-list-item dense>
            <v-chip
              v-model="dispSetting.prospectAmount"
              active-class="light-blue lighten-4"
              @click="dispSetting.prospectAmount = !dispSetting.prospectAmount"
              small
            >
              <v-icon v-text="getDispIcon(dispSetting.prospectAmount)" small left />売上見込
            </v-chip>
            <v-chip
              v-model="dispSetting.resultAmount"
              active-class="light-blue lighten-4"
              @click="dispSetting.resultAmount = !dispSetting.resultAmount"
              small
            >
              <v-icon v-text="getDispIcon(dispSetting.resultAmount)" small left />売上実績
            </v-chip>
            <v-chip
              v-model="dispSetting.resultRate"
              active-class="light-blue lighten-4"
              @click="dispSetting.resultRate = !dispSetting.resultRate"
              small
            >
              <v-icon v-text="getDispIcon(dispSetting.resultRate)" small left />料率
            </v-chip>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <XLoader :loading="initializing">
      <template v-slot="{ loaded }">
        <div v-if="loaded">
          <v-tabs v-model="tab">
            <v-tab> 集計</v-tab>

            <v-tab v-for="month of 12" :key="`month${month}`">
              {{
                $moment(dateStart)
                  .add(month - 1, 'months')
                  .format('M')
              }}月
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card outlined>
                <v-card-text>
                  <!-- {{ rankingDatas }} -->

                  <v-simple-table class="table table-responsive table-striped table-bordered" dense>
                    <template v-slot:default>
                      <thead>
                        <tr class="blue lighten-4">
                          <th scope="col">順位</th>
                          <th scope="col">指名</th>
                          <th v-for="month of 12" :key="`monthHeader${month}`" class="table-secondary">
                            {{
                              $moment(dateStart)
                                .add(month - 1, 'months')
                                .format('M')
                            }}月
                          </th>

                          <th scope="row">合計</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-for="(totalRankingData, index) of totalRankingDatas">
                          <tr :key="`totalRankingData_${index}`">
                            <th>{{ index + 1 }}</th>
                            <td>{{ totalRankingData.employee.name }}</td>
                            <td v-for="month of 12" :key="`monthHeader${month}`">
                              {{ getRankingDatas($moment(dateStart).add(month - 1, 'months'), totalRankingData.employee).total }}
                            </td>

                            <th :class="colors.total" scope="row">{{ totalRankingData.total }}</th>
                          </tr>
                        </template>

                        <tr class="bottom-top">
                          <th colspan="2">合計</th>
                          <th v-for="month of 12" :key="`monthHeader${month}`">
                            {{ getRankingDatas($moment(dateStart).add(month - 1, 'months')).total }}
                          </th>
                          <th>{{ getRankingDatas().total }}</th>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <v-divider />

                  <v-row>
                    <v-col v-for="month of 12" :key="`monthHeader${month}`" cols="6">
                      <v-simple-table dense>
                        <thead>
                          <tr class="blue lighten-4">
                            <th>
                              {{
                                $moment(dateStart)
                                  .add(month - 1, 'months')
                                  .format('M')
                              }}月
                            </th>
                            <th>アポ</th>
                            <th>デモ</th>
                            <th>すけアポ</th>
                            <th>すけデモ</th>
                            <th>スマホアポ</th>
                            <th>スマホデモ</th>
                            <th>計</th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-for="(employee, index) of employees">
                            <tr :key="`monthEmployee${month}_${index}`">
                              <td>
                                {{ employee.name }}
                              </td>
                              <td>{{ getRankingDatas($moment(dateStart).add(month - 1, 'months'), employee).apo }}</td>
                              <td>{{ getRankingDatas($moment(dateStart).add(month - 1, 'months'), employee).demo }}</td>
                              <td>{{ getRankingDatas($moment(dateStart).add(month - 1, 'months'), employee).sukeApo }}</td>
                              <td>{{ getRankingDatas($moment(dateStart).add(month - 1, 'months'), employee).sukeDemo }}</td>
                              <td>{{ getRankingDatas($moment(dateStart).add(month - 1, 'months'), employee).spApo }}</td>
                              <td>{{ getRankingDatas($moment(dateStart).add(month - 1, 'months'), employee).spDemo }}</td>
                              <th :class="colors.total">
                                {{ getRankingDatas($moment(dateStart).add(month - 1, 'months'), employee).total }}
                              </th>
                            </tr>
                          </template>

                          <tr class="bottom-top">
                            <th>合計</th>
                            <th>{{ getRankingDatas($moment(dateStart).add(month - 1, 'months')).apo }}</th>
                            <th>{{ getRankingDatas($moment(dateStart).add(month - 1, 'months')).demo }}</th>
                            <th>{{ getRankingDatas($moment(dateStart).add(month - 1, 'months')).sukeApo }}</th>
                            <th>{{ getRankingDatas($moment(dateStart).add(month - 1, 'months')).sukeDemo }}</th>
                            <th>{{ getRankingDatas($moment(dateStart).add(month - 1, 'months')).spApo }}</th>
                            <th>{{ getRankingDatas($moment(dateStart).add(month - 1, 'months')).spDemo }}</th>
                            <th>{{ getRankingDatas($moment(dateStart).add(month - 1, 'months')).total }}</th>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item v-for="month of 12" :key="`month${month}`">
              <v-card outlined>
                <v-card-text>
                  <v-simple-table dense>
                    <thead>
                      <tr class="blue lighten-4">
                        <th>入金確認</th>
                        <th>リプ</th>
                        <th>屋号</th>
                        <th>契約内容</th>
                        <th>スマホ</th>
                        <th>下取料</th>
                        <th>月額</th>
                        <th>ポイント</th>
                        <th>注意事項</th>
                        <th>アポ</th>
                        <th>デモ</th>

                        <th v-if="dispSetting.prospectAmount">売上見込</th>
                        <th v-if="dispSetting.resultAmount">売上実績</th>
                        <th v-if="dispSetting.resultRate">料率</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="(demo, index) of getDatas($moment(dateStart).add(month - 1, 'months')).demos">
                        <tr :key="`demo${index}`">
                          <td>{{ demo.invoiceDateForPoint | date }}</td>
                          <td>{{ demo.isReplaceContract | boolLabel }}</td>
                          <td>{{ demo.facility.name }}</td>
                          <td>{{ demo.systemTypes.map((a) => $options.filters.demoSystemType(a.value)) | implode(',') }}</td>
                          <td>{{ demo.hasSP | boolLabel }}</td>
                          <td>{{ demo.residualBonds | priceJP }}</td>
                          <td>{{ demo.sales | priceJP }}</td>
                          <td>
                            <v-btn
                              text
                              x-small
                              @click="
                                pointEditor.show = true;
                                pointEditor.demo = demo;
                                pointEditor.point = demo.point != null ? demo.point : demo.pointAuto;
                                pointEditor.pointMessage = demo.pointMessage;
                              "
                            >
                              <span v-if="demo.point == null">{{ demo.pointAuto }}</span>
                              <span v-else>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">
                                      <v-icon color="primary" v-text="`mdi-pencil-circle-outline`" small left />
                                      <span class="text-primary">{{ demo.point }}</span>
                                    </span>
                                  </template>
                                  自動算出の値:{{ demo.pointAuto }}
                                </v-tooltip>
                              </span>
                            </v-btn>
                          </td>
                          <td>{{ demo.pointMessage }}</td>
                          <td>
                            <span v-if="demo.apo != null && demo.apo.apointer != null">{{ demo.apo.apointer.name }}</span>
                            <span class="primary white--text" v-else-if="demo.apo != null && demo.apo.branch != null">{{
                              demo.apo.branch.name
                            }}</span>
                          </td>
                          <td>
                            <span v-if="demo.demointer != null">{{ demo.demointer.name }}</span>
                          </td>

                          <td v-if="dispSetting.prospectAmount">{{ demo.prospectAmount | priceJP }}</td>
                          <td v-if="dispSetting.resultAmount">{{ demo.resultAmount | priceJP }}</td>
                          <td v-if="dispSetting.resultRate">{{ (demo.resultRate / 100) | percent }}%</td>
                        </tr>
                      </template>

                      <!-- 合計について要求にないため（蛇足になるかもしれないので）非活性 -->
                      <!-- <tr class="table-secondary">
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>{{ getRankingDatas($moment(dateStart).add(month - 1, 'months')).total }}</th>
                        <th></th>
                        <th></th>
                        <th></th>

                        <th v-if="dispSetting.prospectAmount"></th>
                        <th v-if="dispSetting.resultAmount"></th>
                        <th v-if="dispSetting.resultRate"></th>
                      </tr> -->
                    </tbody>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </template>
    </XLoader>

    <v-bottom-sheet v-model="pointEditor.show" persistent width="400" offset-y>
      <v-card>
        <v-card-title>
          <v-btn text color="warning" @click="pointEditor.show = false"> 閉じる </v-btn>
          <v-spacer />
          <v-btn color="primary" @click="onCommitPointEditor(pointEditor.demo)"> 保存 </v-btn>
        </v-card-title>
        <v-card-text>
          <v-text-field type="number" v-model.number="pointEditor.point" label="ポイント" clearable outlined dense> </v-text-field>

          <!-- <v-combobox
                                    type="number"
                                    v-model.number="pointEditor.point"
                                    :items="['1.0', '1.5', '2.0', '2.5', '3.0', '3.5', '4.0', '4.5', '5.0', '5.5']"
                                    label="ポイント"
                                    counter
                                    :maxlength="5"
                                    clearable
                                    outlined
                                    dense
                                  ></v-combobox> -->

          <v-textarea v-model="pointEditor.pointMessage" label="注意事項" clearable outlined dense> </v-textarea>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>


<script>
export default {
  components: {},

  filters: {
    boolLabel(value) {
      return value ? '〇' : '';
    },
  },
  computed: {
    colors() {
      return {
        //total: 'table-active',
        total: ' blue-grey lighten-5',
      };
    },

    year: {
      get() {
        if (this.date == null) return null;
        return this.date.getFullYear();
      },
      set(newValue) {
        this.date = new Date(newValue, 6 - 1, 1);
      },
    },

    years() {
      let date = this.$options.filters.getTermFirstDate(new Date());
      // 現在を含めた前３年
      return [...Array(3).keys()].map((a) => {
        let moment = this.$moment(date);
        let d = moment.add(-a, 'years').toDate();
        return {
          text: `${this.$options.filters.libertyTerm(d)}期 `,
          value: d.getFullYear(),
        };
      });
    },

    // 開始日(期首)
    dateStart() {
      // 期首日を取得
      return this.$options.filters.getTermFirstDate(this.date);
    },

    // 終了日(期末の5月の最終日)
    dateEnd() {
      // 期首日を取得
      let moment = this.$moment(this.$options.filters.getTermFirstDate(this.date));
      let year = moment.year();
      return new Date(year + 1, 6 - 1, 0);
    },

    totalRankingDatas() {
      let list = [];
      for (let employee of this.employees) {
        let datas = this.rankingDatas.filter((a) => a.employee.code == employee.code);

        let total = datas.reduce((sum, a) => (sum += a.total), 0);

        let data = {
          employee: employee,
          total: total,
        };
        //
        list.push(data);
      }

      // ポイント降順にソート
      list.sort((a, b) => {
        if (a.total > b.total) return -1;
        if (a.total < b.total) return 1;
        return 0;
      });

      return list;
    },
  },

  data: () => ({
    initializing: false,

    date: null,

    tab: 0,

    dispSetting: {
      prospectAmount: false,
      resultAmount: false,
      resultRate: false,
    },

    pointEditor: {
      show: false,
      demo: null,
      point: null,
      pointMessage: null,
    },

    datas: [],
    employees: [],
    rankingDatas: [],
  }),

  methods: {
    async onLoaded() {
      this.initializing = true;

      // 初期表示時は当期首月を指定する
      if (this.date == null) {
        this.date = this.$options.filters.getTermFirstDate(new Date());
      }

      // 指定期間の営業ポイント情報取得
      {
        let dateStart = this.$moment(this.dateStart).format();
        let dateEnd = this.$moment(this.dateEnd).format();

        let success = await this.get('analyse/datas/point', { dateStart: dateStart, dateEnd: dateEnd });
        let data = success.data;
        this.datas = data;

        // アポインターとデモ担当から社員情報を取得する
        // FIXME 冗長なので直すこと
        let demoPoints = this.datas.flatMap((a) => a.demoPoints);
        let apointers = demoPoints
          .filter((a) => a.apo != null)
          .filter((a) => a.apo.apointer != null)
          .map((a) => a.apo.apointer);
        let demointers = demoPoints.filter((a) => a.demointer != null).map((a) => a.demointer);
        //
        let employees = [];
        for (let employee of apointers) {
          if (!employees.map((a) => a.code).includes(employee.code)) employees.push(employee);
        }
        for (let employee of demointers) {
          if (!employees.map((a) => a.code).includes(employee.code)) employees.push(employee);
        }
        this.employees = employees;
      }

      // ランキングデータ作成
      {
        this.rankingDatas = this.createRankingDatas(this.datas, this.employees);
      }

      // 初期化終了
      this.initializing = false;
    },

    onDateChanged() {
      // リロードする
      this.onLoaded();
    },

    onCommitPointEditor(demo) {
      let point = this.pointEditor.point;
      let pointMessage = this.pointEditor.pointMessage;
      // 空の場合はnull(0はありうる)
      if (point === '') point = null;

      let request = {
        demoCode: demo.code,
        point: point,
        pointMessage: pointMessage,
      };

      // nullable
      request.point = this.convertNumber(request.point);

      //
      this.put('demo/point', request)
        .then((success) => {
          //
          this.showSuccessSnackbar(success);

          // 表示更新
          this.pointEditor.show = false;
          demo.point = point;
          demo.pointMessage = pointMessage;
        })
        .catch((error) => this.showErrorSnackbar(error));
    },

    createRankingDatas(datas, employees) {
      //
      let list = [];

      for (let i = 0; i < 12; i++) {
        let date = this.$moment(this.dateStart).add(i, 'months');
        //
        let year = date.year();
        let month = date.month() + 1;
        //
        let datas2 = datas.filter((a) => a.year == year).filter((a) => a.month == month);
        let demos = datas2.flatMap((a) => a.demoPoints);

        // console.log('date', `${year}/${month}`);
        // console.log('demos', demos);

        //
        for (let employee of employees) {
          let apos = demos.filter((a) => a.apo != null && a.apo.apointer != null && a.apo.apointer.code == employee.code);
          let demos2 = demos.filter((a) => a.demointer != null && a.demointer.code == employee.code);

          // console.log('apos', apos);
          // console.log('demos2', demos2);

          let apo = apos.reduce((sum, a) => (sum += a.resultPoint), 0);
          let demo = demos2.reduce((sum, a) => (sum += a.resultPoint), 0);
          let sukeApo = apos.filter((a) => a.hasSukesan).reduce((sum, a) => (sum += a.resultPoint), 0);
          let sukeDemo = demos2.filter((a) => a.hasSukesan).reduce((sum, a) => (sum += a.resultPoint), 0);
          let spApo = apos.filter((a) => a.hasSP).reduce((sum, a) => (sum += a.resultPoint), 0);
          let spDemo = demos2.filter((a) => a.hasSP).reduce((sum, a) => (sum += a.resultPoint), 0);

          // console.log('apo', apo);
          // console.log('demo', demo);
          // console.log('sukeApo', sukeApo);
          // console.log('sukeDemo', sukeDemo);
          // console.log('spApo', spApo);
          // console.log('apspDemoo', spDemo);
          //
          let total = 0;
          total += apo;
          total += demo;
          total += sukeApo;
          total += sukeDemo;
          total += spApo;
          total += spDemo;

          let data = {
            year: year,
            month: month,
            employee: employee,
            apo: apo,
            demo: demo,
            sukeApo: sukeApo,
            sukeDemo: sukeDemo,
            spApo: spApo,
            spDemo: spDemo,
            total: total,
          };
          //
          list.push(data);
        }
      }
      return list;
    },

    getDatas(moment) {
      let datas = this.datas;

      if (moment != null) {
        let year = moment.year();
        let month = moment.month() + 1;
        datas = datas.filter((a) => a.year == year).filter((a) => a.month == month);
      }
      //
      let demos = datas.flatMap((a) => a.demoPoints);

      return {
        demos: demos,
      };
    },

    getRankingDatas(moment, employee) {
      //
      let datas = this.rankingDatas;

      if (moment != null) {
        let year = moment.year();
        let month = moment.month() + 1;

        datas = datas.filter((a) => a.year == year).filter((a) => a.month == month);
      }
      //
      if (employee != null) datas = datas.filter((a) => a.employee.code == employee.code);
      //
      let apo = datas.reduce((sum, a) => (sum += a.apo), 0);
      let demo = datas.reduce((sum, a) => (sum += a.demo), 0);
      let sukeApo = datas.reduce((sum, a) => (sum += a.sukeApo), 0);
      let sukeDemo = datas.reduce((sum, a) => (sum += a.sukeDemo), 0);
      let spApo = datas.reduce((sum, a) => (sum += a.spApo), 0);
      let spDemo = datas.reduce((sum, a) => (sum += a.spDemo), 0);
      let total = datas.reduce((sum, a) => (sum += a.total), 0);

      return {
        apo: apo,
        demo: demo,
        sukeApo: sukeApo,
        sukeDemo: sukeDemo,
        spApo: spApo,
        spDemo: spDemo,
        total: total,
      };
    },

    getDispIcon(val) {
      return val ? 'mdi-eye' : 'mdi-eye-off';
    },
  },
  watch: {},
  async mounted() {
    await this.onLoaded();
  },
};
</script>